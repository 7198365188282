.profileContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #fff;
    padding: 10px;
}
.profileHeader{
    display: flex;
    flex-direction: row;
}
