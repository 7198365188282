.reportTabContainer {
    padding: 15px;
    border-radius: 25px; /* Container rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    background-color: #ffffff; /* White background */
}








.customTabs .ant-tabs-nav {
    margin: 0; /* Remove margin around the tabs */
    padding: 0; /* Remove padding if any */
    border-bottom: none; /* Ensure no bottom border */
}

.customTabs {
    padding-left: 0; /* Remove padding on the left */
    padding-right: 0; /* Remove padding on the right */
}

.customTabs .ant-tabs-nav {
    margin-bottom: 0;
    border-bottom: none;
}

.customTabs .ant-tabs-tab {
    border-radius: 10px 10px 10px 10px;
    background-color: #f6f9fc; /* Light background for inactive tabs */
    padding: 5px 5px;
    margin: 0;
    transition: all 0.3s;
    color: #1890ff;
    border: 1px solid #1890ff;
}

.customTabs .ant-tabs-tab-active {
    background-color: #ffffff; /* Background for active tab */
    color: #000000 !important; /* Blue text color for active tab 1890ff */
    font-weight: bold; /* Bold text */

}

.customTabs .ant-tabs-tab:hover {
    background-color: #d1e9ff; /* Light blue background on hover */
}

.customTabs .ant-tabs-content-holder {
    border-radius: 0 0 25px 25px; /* Content holder rounded corners */
    border: 1px solid #d9d9d9; /* Light border */
    padding: 10px;
}

.customTabs .ant-tabs-tab:hover {
    background-color: #d1e9ff; /* Light blue background on hover */
    color: #1890ff; /* Optional: change hover text color */
}

